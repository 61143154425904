import React from 'react'

export default function Footer2() {
    return (
        <>
            <footer className="footer brand-1">
                <div className="container">
                    <div className="footer__wrapper">
                        {/* <div className="footer__top footer__top--style1">
                            <div className="row gy-5 gx-4 justify-content-center">
                                <div className="col-md-8">
                                    <div className="footer__about text-center">
                                        <a href="index-2.html" className="footer__about-logo"><img src="assets/images/logo/logo-dark.png" alt="Logo" /></a>
                                        <p className="text-white">
                                            Welcome to our trading site! We offer the best, most affordable products and services around. Shop now and
                                            start finding great deals!
                                        </p>
                                        <div className="footer__app justify-content-center">
                                            <div>
                                                <ul className="social">
                                                    <li className="social__item">
                                                        <a href="#" className="social__link social__link--style22"><i className="fab fa-facebook-f" /></a>
                                                    </li>
                                                    <li className="social__item">
                                                        <a href="#" className="social__link social__link--style22"><i className="fab fa-instagram" /></a>
                                                    </li>
                                                    <li className="social__item">
                                                        <a href="#" className="social__link social__link--style22"><i className="fa-brands fa-linkedin-in" /></a>
                                                    </li>
                                                    <li className="social__item">
                                                        <a href="#" className="social__link social__link--style22"><i className="fab fa-youtube" /></a>
                                                    </li>
                                                    <li className="social__item">
                                                        <a href="#" className="social__link social__link--style22"><i className="fab fa-twitter" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="footer__bottom">
                            <div className="footer__end justify-content-center">
                                <div className="footer__end-copyright">
                                    <p className="mb-0">© 2023 All Rights Reserved By <a href="#" target="_blank">Oxyo2farm</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__shape">
                    <span className="footer__shape-item footer__shape-item--1"><img src="assets/images/footer/1.png" alt="shape icon" /></span>
                    <span className="footer__shape-item footer__shape-item--2"> <span /> </span>
                    <span className="footer__shape-item footer__shape-item--4"><img src="assets/images/icon/shape/2.png" alt="shape icon" /></span>
                    <span className="footer__shape-item footer__shape-item--5"><img src="assets/images/icon/shape/1.png" alt="shape icon" /></span>
                </div>
            </footer>

        </>
    )
}
