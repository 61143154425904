import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Hero from './Hero'
import Steps from './Steps'
import Oxyo2farm from './Oxyo2farm'
import FarmPackage from './FarmPackage'
import Tokenomics from './Tokenomics'
import FAQ from './FAQ'
import StakedAssets from './StakedAssets'
import Roadmap from './Roadmap'
import Rate from './Rate'
import Coin from './Coin'
import GetStarted from './GetStarted'
import Platform from './Platform'

export default function Home() {
    return (
        <>
            <Header />
            <Hero />
            <Steps />
            <Rate />
            <Platform />
            <Coin />
            <Oxyo2farm />
            <StakedAssets />
            <Roadmap />
            <FarmPackage />
            <Tokenomics />
            <FAQ />
            <GetStarted />
            <Footer />
        </>
    )
}
