import React, { useState, useEffect } from 'react'
import Sidebar2 from '../Comman/Sidebar2'
import Footer2 from '../Comman/Footer2'
import InfiniteScroll from 'react-infinite-scroll-component';
import FadingLoaderCard1 from "../Comman/ContentLoaders/LoaderBigLine"

export default function Statement() {
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [pages, setPages] = useState(2);
    const [spinner, setSpinner] = useState(true);

    useEffect(() => {
        const getComments = async () => {

            const res = await fetch(
                `https://jsonplaceholder.typicode.com/comments?_pages=1&_limit=20`
            );
            const data = await res.json();
            setItems(data);
            setTimeout(() => {
                setSpinner(false);
            }, 1000);

        };
        getComments();
    }, []);

    const fetchComments = async () => {

        const res = await fetch(
            `https://jsonplaceholder.typicode.com/comments?_pages=${pages}&_limit=40`
        );
        const data = await res.json();
        // setItems(data);
        return data;
    };


    const fetchData = async () => {
        const commentsFromServer = await fetchComments();
        setItems([...items, ...commentsFromServer]);
        if (commentsFromServer.length === 0 || commentsFromServer.length < 20) {
            setHasMore(false);
        }
        setPages(pages + 1);
    };
    return (
        <>
            <Sidebar2 />
            <div className="blog padding-top padding-bottom">
                <div className="container vh-70">
                    <div className="blog__wrapper">
                        <div className="row g-4">
                            <div className="col-sm-12 col-lg-12">
                                <div className="sidebar__search d-flex flex-wrap justify-content-between align-items-center my-4 bg-transparent">
                                    <h4 className="mb-10">Statement</h4>
                                    <div className="sidebar__search-body bg-transparent">
                                        <form className action="#">
                                            <div className="input">
                                                <input type="text" className="form-control" placeholder="Search" />
                                                <button type="submit" className="search-btn"><svg className="svg-inline--fa fa-magnifying-glass" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg><path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg>{/* <i class="fa-solid fa-magnifying-glass"></i> Font Awesome fontawesome.com */}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div class="table-responsive text-center">
                                    <InfiniteScroll
                                        dataLength={items.length} //This is important field to render the next data
                                        next={fetchData}
                                        hasMore={hasMore}
                                        loader={<h4>Loading...</h4>}
                                        endMessage={
                                            <p style={{ textAlign: 'center' }}>
                                                <b>Yay! You have seen it all</b>
                                            </p>
                                        }

                                    >
                                        <table class="table overflow bg-transparent table-hover">
                                            <thead>
                                                <tr className='text-center'>
                                                    <th scope="col">Sr.</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Credit</th>
                                                    <th scope="col">Debit</th>
                                                    <th scope="col">Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {spinner ? (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="250" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="250" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="250" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="250" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="250" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                        </tr>
                                                    </>
                                                ) :
                                                    <>
                                                        {items.map((item) => {
                                                            return <tr key={item.id}>
                                                                <th scope="row">{item.id}</th>
                                                                <td>01-01-2023</td>
                                                                <td>Otto</td>
                                                                <td>$1000</td>
                                                                <td>$100</td>
                                                                <td className="text-center">100 oxyo2</td>
                                                            </tr>
                                                        })}
                                                    </>
                                                }
                                            </tbody>
                                        </table>
                                    </InfiniteScroll>
                                </div>
                                <div className="blog__item aos-init" data-aos="fade-up" data-aos-duration={800}>
                                    <div className="blog__item-inner blog__item-inner--style2">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer2 />
        </>
    )
}
