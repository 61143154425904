import React, { useState, useEffect } from 'react'
import { Line, Circle } from 'rc-progress';
import ProgressBar from "../Comman/ProgressBar";
import Timer from './Timer';
const appStyles = {
    height: 25,
    // width: 500,
    // margin: 50
};
export default function Coin() {
    const [progress, setProgress] = useState(0);
    const [show, setShow] = useState(false);

    const handelClick = () => {
        setShow(true);
    }
    const handelClickClose = () => {
        setShow(false);
    }
    useEffect(() => {
        setInterval(() => setProgress(Math.floor(Math.random() * 100) + 1), 1000);
    }, []);
    return (
        <>
            <section className="pricing padding-top padding-bottom bg--cover" style={{ backgroundImage: 'url(assets/images/pricing/bg.png)' }}>
                <div className="section-header section-header--max50">
                    <h2 className="mb-15 mt-minus-5">OUR <span>COIN</span></h2>
                    <p>Decentralized cryptocurrency is produced by the entire cryptocurrency system collectively
                        at a rate which is defined when the system is created and which is publicly known.</p>
                </div>
                <div className="container">
                    <div className="pricing__wrapper">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-6">
                                <div className="pricing__item" data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                                    <div className="pricing__item-inner">
                                        <div className="pricing__item-content">
                                            {/* pricing top part */}
                                            <div className="pricing__item-top text-center">
                                                <h6 className="mb-15">Current Supply</h6>
                                                <h3 className="mb-25">908977084.76<span></span> </h3>
                                            </div>
                                            {/* pricing middle part */}
                                            <div className="pricing__item-middle pb-0">
                                                <ul className="pricing__list d-flex flex-wrap">
                                                    <li className="pricing__list-item my-3">
                                                    </li>
                                                </ul>
                                                <div className="w-100">
                                                    <div className='d-flex flex-wrap justify-content-between'>
                                                        <p className="float-start"><strong>Till Now</strong></p>
                                                        <p className="float-end"><strong>91022915.2375 KRPZA</strong></p>
                                                    </div>
                                                    <div className="App" style={appStyles}>
                                                        <ProgressBar bgColor={"#fff200"} progress={progress} />
                                                    </div>
                                                    <h5 className="text-center mt-5">Burned Coin</h5>
                                                </div>
                                                <h2 className='text-center'><Timer />
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex flex-wrap justify-content-between">
                                <div>
                                    <div className="about__content about__content--style2 aos-init aos-animate " data-aos="fade-right" data-aos-duration={800}>
                                        <div className="about__content-inner align-items-center">
                                            <div className="about__icon">
                                                <div className="about__icon-inner">
                                                    <img src="assets/images/logo/preloader.png" alt="about-icon" className="dark" />
                                                </div>
                                            </div>
                                            <div className="about__content-details">
                                                <p>Name:</p>
                                                <h6> OXYO2 (KRPZA)</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="about__content about__content--style2 aos-init aos-animate" data-aos="fade-right" data-aos-duration={800}>
                                        <div className="about__content-inner align-items-center">
                                            <div className="about__icon">
                                                <div className="about__icon-inner">
                                                    <img src="assets/images/about/icon/1.png" alt="about-icon" className="dark" />
                                                </div>
                                            </div>
                                            <div className="about__content-details">
                                                <p>Decimals:</p>
                                                <h6>18</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="about__content about__content--style2 aos-init aos-animate" data-aos="fade-right" data-aos-duration={800}>
                                        <div className="about__content-inner align-items-center">
                                            <div className="about__icon">
                                                <div className="about__icon-inner">
                                                    <img src="assets/images/about/icon/1.png" alt="about-icon" className="dark" />
                                                </div>
                                            </div>
                                            <div className="about__content-details">
                                                <p>Network:</p>
                                                <h6>Binance Chain (BEP20)</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="about__content about__content--style2 aos-init aos-animate" data-aos="fade-right" data-aos-duration={800}>
                                        <div className="about__content-inner align-items-center">
                                            <div className="about__icon">
                                                <div className="about__icon-inner">
                                                    <img src="assets/images/about/icon/1.png" alt="about-icon" className="dark" />
                                                </div>
                                            </div>
                                            <div className="about__content-details">
                                                <p>Burning:</p>
                                                <h6>50%</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="about__content about__content--style2 aos-init aos-animate" data-aos="fade-right" data-aos-duration={1000}>
                                        <div className="about__content-inner align-items-center">
                                            <div className="about__icon">
                                                <div className="about__icon-inner">
                                                    <img src="assets/images/about/icon/3.png" alt="about-icon" className="dark" />
                                                </div>
                                            </div>
                                            <div className="about__content-details">
                                                <p>Live Rate:</p>
                                                <h6>1 KRPZA = $0.0369</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="about__content about__content--style2 aos-init aos-animate" data-aos="fade-right" data-aos-duration={900}>
                                        <div className="about__content-inner align-items-center">
                                            <div className="about__icon">
                                                <div className="about__icon-inner">
                                                    <img src="assets/images/about/icon/2.png" alt="about-icon" className="dark" />
                                                </div>
                                            </div>
                                            <div className="about__content-details">
                                                <p>Max Supply:</p>
                                                <h6 className='text-break'>100,000,0000</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="about__content about__content--style2 aos-init aos-animate" data-aos="fade-right" data-aos-duration={1000}>
                                        <div className="about__content-inner align-items-center">
                                            <div className="about__icon">
                                                <div className="about__icon-inner">
                                                    <img src="assets/images/about/icon/3.png" alt="about-icon" className="dark" />
                                                </div>
                                            </div>
                                            <div className="about__content-details">
                                                <p>Token Launch Rate :</p>
                                                <h6>1 KRPZA = $0.005</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="about__content about__content--style2 aos-init aos-animate" data-aos="fade-right" data-aos-duration={1000}>
                                    <div className="about__content-inner align-items-center">
                                        <div className="about__icon">
                                            <div className="about__icon-inner">
                                                <img src="assets/images/about/icon/3.png" alt="about-icon" className="dark" />
                                            </div>
                                        </div>
                                        <div className="about__content-details">
                                            <h6>Contract Address:</h6>
                                            <p className='text-break'>0x53940d46a35162255511ff7cade811891d49533c</p>
                                        </div>
                                    </div>
                                </div>
                                <p className='text-green'>Notice! Be aware of fraud contracts & Please verify the below Token contract address carefully before making any Transaction.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pricing__shape">
                    <span className="pricing__shape-item pricing__shape-item--5"> <img src="assets/images/icon/shape/3.png" alt="shape-icon" /></span>
                    <span className="pricing__shape-item pricing__shape-item--6"> <img src="assets/images/icon/shape/1.png" alt="shape-icon" />
                    </span>
                </div>
            </section>
        </>
    )
}
