import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Components/Home/Home';
import Dashboard from './Components/Dashboard/Dashboard';
import Farming from './Components/Dashboard/Farming';
import Harvest from './Components/Dashboard/Harvest';
import ReferralReward from './Components/Dashboard/ReferralReward';
import Withdrawals from './Components/Dashboard/Withdrawals';
import Statement from './Components/Dashboard/Statement';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/farming" element={<Farming />} />
          <Route path="/harvest" element={<Harvest />} />
          <Route path="/referral-reward" element={<ReferralReward />} />
          <Route path="/withdrawals" element={<Withdrawals />} />
          <Route path="/statement" element={<Statement />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
