import React from "react";
import ContentLoader from "react-content-loader";

export default function FadingLoaderCard1(props) {
    return (
        <ContentLoader
            speed={2}
            // width={300}
            // height={40}
            // viewBox="0 0 300 45"
            // backgroundColor="#f3f3f3"
            // foregroundColor="#ecebeb"
            // backgroundColor="#00000063"
            // foregroundColor="#f3eded0f"
            backgroundColor={'#00000063'}
            foregroundColor={'#999'}    
            {...props}
        >
            <rect x={props.x} y={props.y} rx={props.rx} ry={props.ry} width={props.width} height={props.height} />
        </ContentLoader >
    );
}
