import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function Header() {
    const [show, setShow] = useState(false);
    const [showmenu, setShowMenu] = useState(false);

    const handelClick = () => setShowMenu(true);
    const handelClickClose = () => setShowMenu(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <header id="header" className="header-section header-section--style2 d-block">
                <div className="header-bottom">
                    <div className="container">
                        <div className="header-wrapper">
                            <div className="logo">
                                <a href="/">
                                    <img className="dark" src="assets/images/logo/logo-dark.png" alt="logo" />
                                </a>
                            </div>
                            <div className="menu-area">
                                <ul className={showmenu ? "menu menu--style1 active" : "menu menu--style1"} >
                                    <li>
                                        <a href="#0">Oxyo2farm </a>
                                    </li>
                                    <li>
                                        <a href="#0">Packages </a>
                                    </li>
                                    <li>
                                        <a href="#0">Tokenomics </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="header-action">
                                <div className="menu-area">
                                    {/* <div className="header-btn">
                                        <button type="button" className="trk-btn trk-btn--border trk-btn--primary" onClick={handleShow}>
                                        Connect Wallet
                                        </button>
                                    </div> */}
                                    {/* toggle icons */}
                                    {showmenu ?
                                        <div className={showmenu ? "header-bar d-lg-none header-bar--style1 active" : "header-bar d-lg-none header-bar--style1 "}>
                                            <div onClick={handelClickClose}>
                                                <span />
                                                <span />
                                                <span />
                                            </div>
                                        </div>
                                        :
                                        <div className={showmenu ? "header-bar d-lg-none header-bar--style1 active" : "header-bar d-lg-none header-bar--style1 "}>
                                            <div onClick={handelClick}>
                                                <span />
                                                <span />
                                                <span />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="header-btn justify-content-end">
                                <button type="button" className="trk-btn trk-btn--border trk-btn--primary" onClick={handleShow}>
                                    Connect Wallet
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </header>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Connect Wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                        <a href='/dashboard' type="button" className="trk-btn trk-btn--border trk-btn--secondary m-1 align-items-center p-2">
                            <img src="./assets/images/icon/metamask.png" className='me-4' width="40px" alt="" />
                            <span>Metamask</span>
                        </a>
                        <a href='/dashboard' type="button" className="trk-btn trk-btn--border trk-btn--primary m-1 align-items-center p-2">
                            <img src="./assets/images/icon/wallet-connect.png" className='mx-4' width="70px" alt="" />
                            <span>Wallet Connect</span></a>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    )
}
