import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"

export default function Roadmap() {
    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,     
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }
    return (
        <>
            <section className="roadmap roadmap--style2 padding-top  padding-bottom bg-color">
                <div className="container">
                    <div className="section-header section-header--max50">
                        <h2 className="mb-15 mt-minus-5"> <span className="style2">Roadmap </span> of plarform</h2>
                        <p>A product roadmap shows the path ahead, helps teams plan, and guides the delivery of the product.</p>
                    </div>
                    <div className="roadmap__wrapper d-none d-lg-block">
                        <div className="roadmap__upper">
                            <div className="roadmap__upper-inner">
                                <div className="swiper">
                                    <Slider {...settings}>
                                        <div className="roadmap__slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                                            <div className="swiper-wrapper" id="swiper-wrapper-6585b43acb647b5e" aria-live="off" style={{ cursor: 'grab', transitionDuration: '0ms', transform: 'translate3d(0px, 0px, 0px)' }}>
                                                <div className="swiper-slide swiper-slide-active" role="group" aria-label="1 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-bottom">
                                                            <div className="roadmap__item-content">
                                                                <h5>OxyO2 <br /> Token Development</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2022 Q1</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide swiper-slide-next" role="group" aria-label="2 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item roadmap__item--reverse">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-top">
                                                            <div className="roadmap__item-content">
                                                                <h5>Smart <br /> Contract Audit</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2022 Q1</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide" role="group" aria-label="3 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-bottom">
                                                            <div className="roadmap__item-content">
                                                                <h5>Social <br /> Media Airdrop</h5>
                                                                <p> </p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2022 Q1</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide" role="group" aria-label="4 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item roadmap__item--reverse">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-top">
                                                            <div className="roadmap__item-content">
                                                                <h5>IDO <br /> PHASE 1</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2022 Q1</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
                                        </div>
                                        <div className="roadmap__slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                                            <div className="swiper-wrapper" id="swiper-wrapper-6585b43acb647b5e" aria-live="off" style={{ cursor: 'grab', transitionDuration: '0ms', transform: 'translate3d(0px, 0px, 0px)' }}>
                                                <div className="swiper-slide" role="group" aria-label="6 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-bottom">
                                                            <div className="roadmap__item-content">
                                                                <h5>Staking <br />Program</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2022 Q1</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide" role="group" aria-label="7 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item roadmap__item--reverse">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-top">
                                                            <div className="roadmap__item-content">
                                                                <h5>Brokerage <br /> Platform</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2022 Q1</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide" role="group" aria-label="8 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-bottom">
                                                            <div className="roadmap__item-content">
                                                                <h5>Exchange <br />Listing</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2022 Q2</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide" role="group" aria-label="9 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item roadmap__item--reverse">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-top">
                                                            <div className="roadmap__item-content">
                                                                <h5>CMC &amp; Coingecko <br />Listing</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2022 Q3</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
                                        </div>
                                        <div className="roadmap__slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                                            <div className="swiper-wrapper" id="swiper-wrapper-6585b43acb647b5e" aria-live="off" style={{ cursor: 'grab', transitionDuration: '0ms', transform: 'translate3d(0px, 0px, 0px)' }}>
                                                <div className="swiper-slide swiper-slide-active" role="group" aria-label="1 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-bottom">
                                                            <div className="roadmap__item-content">
                                                                <h5>CMC &amp; Coingecko <br />Listing</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2022 Q3</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide swiper-slide-next" role="group" aria-label="2 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item roadmap__item--reverse">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-top">
                                                            <div className="roadmap__item-content">
                                                                <h5>Swap <br /> Listing</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2022 Q3</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide" role="group" aria-label="3 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-bottom">
                                                            <div className="roadmap__item-content">
                                                                <h5>Play <br />to Earn</h5>
                                                                <p> </p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2022 Q3</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide" role="group" aria-label="4 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item roadmap__item--reverse">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-top">
                                                            <div className="roadmap__item-content">
                                                                <h5>Exchange <br />Airdrop</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2022 Q4</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
                                        </div>
                                        <div className="roadmap__slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                                            <div className="swiper-wrapper" id="swiper-wrapper-6585b43acb647b5e" aria-live="off" style={{ cursor: 'grab', transitionDuration: '0ms', transform: 'translate3d(0px, 0px, 0px)' }}>
                                                <div className="swiper-slide" role="group" aria-label="6 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-bottom">
                                                            <div className="roadmap__item-content">
                                                                <h5>OxyO2 <br />Exchange Live</h5>
                                                                <p>Live Now</p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2022 Q4</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide" role="group" aria-label="7 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item roadmap__item--reverse">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-top">
                                                            <div className="roadmap__item-content">
                                                                <h5>OxyO2 <br />Farming</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2023 Q1</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide" role="group" aria-label="8 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-bottom">
                                                            <div className="roadmap__item-content">
                                                                <h5>OxyO2 <br />NFT Market</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2023 Q2</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide" role="group" aria-label="9 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item roadmap__item--reverse">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-top">
                                                            <div className="roadmap__item-content">
                                                                <h5>OxyO2 <br />Blockchain</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2023 Q2</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
                                        </div>
                                        <div className="roadmap__slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                                            <div className="swiper-wrapper" id="swiper-wrapper-6585b43acb647b5e" aria-live="off" style={{ cursor: 'grab', transitionDuration: '0ms', transform: 'translate3d(0px, 0px, 0px)' }}>
                                                <div className="swiper-slide swiper-slide-active" role="group" aria-label="1 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-bottom">
                                                            <div className="roadmap__item-content">
                                                                <h5>OxyO2 <br />D-Wallet</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2023 Q3</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide swiper-slide-next" role="group" aria-label="2 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item roadmap__item--reverse">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-top">
                                                            <div className="roadmap__item-content">
                                                                <h5>OxyO2 <br />D-SWAP</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2023 Q3</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide" role="group" aria-label="3 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-bottom">
                                                            <div className="roadmap__item-content">
                                                                <h5>OxyO2 <br />METAVERSE</h5>
                                                                <p> </p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2023 Q4</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide" role="group" aria-label="4 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item roadmap__item--reverse">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-top">
                                                            <div className="roadmap__item-content">
                                                                <h5>OxyO2 <br />Utility</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2023 Q4</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
                                        </div>
                                        <div className="roadmap__slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                                            <div className="swiper-wrapper" id="swiper-wrapper-6585b43acb647b5e" aria-live="off" style={{ cursor: 'grab', transitionDuration: '0ms', transform: 'translate3d(0px, 0px, 0px)' }}>
                                                <div className="swiper-slide" role="group" aria-label="6 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-bottom">
                                                            <div className="roadmap__item-content">
                                                                <h5>OxyO2 <br />E-Wallet Security</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2024 Q1</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide" role="group" aria-label="7 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item roadmap__item--reverse">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-top">
                                                            <div className="roadmap__item-content">
                                                                <h5>OxyO2 <br />1st Game</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2024 Q1</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide" role="group" aria-label="8 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-bottom">
                                                            <div className="roadmap__item-content">
                                                                <h5>OxyO2 <br />Magic World</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2024 Q2</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide" role="group" aria-label="9 / 10" style={{ width: '279px', marginRight: '10px' }}>
                                                    <div className="roadmap__item roadmap__item--reverse">
                                                        <div className="roadmap__item-inner roadmap__item-inner--vertical-line-top">
                                                            <div className="roadmap__item-content">
                                                                <h5>OxyO2 <br />NEW ROAD MAP</h5>
                                                                <p></p>
                                                            </div>
                                                            <div className="roadmap__item-date">
                                                                <span>2024 Q2</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
                                        </div>
                                    </Slider>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row g-4 align-items-center justify-content-center  d-block d-lg-none d-md-block">
                        <Slider {...settings}>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">OxyO2 <br /> Token Development</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2022 Q1</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">Smart <br /> Contract Audit</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2022 Q1</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">Social <br /> Media Airdrop</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2022 Q1</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">IDO <br /> PHASE 1</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2022 Q1</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">Staking <br />Program</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2022 Q1</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">Brokerage <br /> Platform</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2022 Q1</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">Exchange <br />Listing</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2022 Q2</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">CMC &amp; Coingecko <br />Listing</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2022 Q3</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">Swap <br /> Listing</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2022 Q3</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">Play <br />to Earn</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2022 Q3</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">Exchange <br />Airdrop</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2022 Q4</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">OxyO2 <br />Exchange Live</a> </h5>
                                            <p>Live Now</p>
                                            <div className="roadmap__item-date">
                                                <span>2022 Q4</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">OxyO2 <br />Farming</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2023 Q1</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">OxyO2 <br />NFT Market</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2023 Q2</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">OxyO2 <br />Blockchain</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2023 Q2</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">OxyO2 <br />D-Wallet</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2023 Q3</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">OxyO2 <br />D-SWAP</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2023 Q3</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">OxyO2 <br />METAVERSE</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2023 Q4</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">OxyO2 <br />Utility</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2023 Q4</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">OxyO2 <br />E-Wallet Security</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2024 Q1</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">OxyO2 <br />1st Game</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2024 Q1</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">OxyO2 <br />Magic World</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2024 Q2</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 my-4">
                                <div className="service__item service__item--style2">
                                    <div className="service__item-inner text-center">
                                        <div className="service__content">
                                            <h5 className="mb-15"> <a className="stretched-link">OxyO2 <br />NEW ROAD MAP</a> </h5>
                                            <div className="roadmap__item-date">
                                                <span>2024 Q2</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>

                    </div>
                </div>
            </section>

        </>
    )
}
