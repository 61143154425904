import React from 'react'

export default function Tokenomics() {
    return (
        <>
            <section className="about about--style3 padding-top padding-bottom bg--cover" style={{ backgroundImage: 'url(assets/images/feature/home3/bg.png)' }}>
                <div className="container padding-bottom">
                    <div className="about__wrapper">
                        <div className="about__content-icon">
                            <img src="assets/images/banner/home3/2.png" alt="trade icon" />
                        </div>
                        <div className="row align-items-center justify-content-center p-0">
                            <h2 className="text-center">Tokenomics</h2>
                            <img className="dark w-100" src="assets/images/about/home3/tokenomics-dark.png" alt="about-image" />
                            <div className="col-md-7">
                                <div className="about__thumb" data-aos="fade-left" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                                    <div className="about__thumb-inner">
                                        <div className="about__thumb-image text-center floating-content">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
