import React, { useState, useEffect } from 'react'
import { Line, Circle } from 'rc-progress';
import ProgressBar from "../Comman/ProgressBar";
const appStyles = {
    height: 25,
    // width: 500,
    // margin: 50
};
export default function FarmPackage() {
    const [progress, setProgress] = useState(0);
    const [show, setShow] = useState(false);

    const handelClick = () => {
        setShow(true);
    }
    const handelClickClose = () => {
        setShow(false);
    }
    useEffect(() => {
        setInterval(() => setProgress(Math.floor(Math.random() * 100) + 1), 1000);
    }, []);


    return (
        <>
            <section className="pricing padding-top padding-bottom bg--cover" style={{ backgroundImage: 'url(assets/images/pricing/bg.png)' }}>
                <div className="section-header section-header--max50">
                    <h2 className="mb-15 mt-minus-5"><span>Farm </span>Package</h2>
                    <p>We offer the best pricings around - from installations to repairs, maintenance, and more!</p>
                </div>
                <div className="container">
                    <div className="pricing__wrapper">
                        <div className="row g-4 align-items-center justify-content-center">
                            <div className="col-md-12 col-lg-11">
                                <div className="pricing__item" data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                                    <div className="pricing__item-inner">
                                        <div className="pricing__item-content">
                                            {/* pricing top part */}
                                            <div className="pricing__item-top d-flex justify-content-between">
                                                <div>
                                                    <h6 className="mb-15">Duration</h6>
                                                    <h3 className="mb-25">30 <span>days</span> </h3>
                                                </div>
                                                <div className="w-50">
                                                    <p className="float-start">Min: <strong>1 </strong></p>
                                                    <p className="float-end">Max: <strong>100 </strong></p>
                                                    <div className="App" style={appStyles}>
                                                        <ProgressBar bgColor={"#fff200"} progress={progress} />
                                                    </div>
                                                    {/* <div className="progress w-100">
                                                        <div className="progress-done">
                                                            70%
                                                        </div>
                                                    </div> */}
                                                    <p className="text-center mt-5">Current Stake</p>
                                                </div>
                                            </div>
                                            {/* pricing middle part */}
                                            <div className="pricing__item-middle pb-0">
                                                <ul className="pricing__list d-flex flex-wrap justify-content-around">
                                                    <li className="pricing__list-item my-3">
                                                        <h5>15%</h5>
                                                        APY
                                                    </li>
                                                    <li className="pricing__list-item my-3">
                                                        <h5>15000000</h5>
                                                        Max Allocation
                                                    </li>
                                                    <li className="pricing__list-item my-3">
                                                        <h5>$1</h5>
                                                        Min Farm
                                                    </li>
                                                    <li className="pricing__list-item my-3">
                                                        <div className="pricing__item-bottom">
                                                            <button type="button" className="trk-btn trk-btn--border trk-btn--primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                Stake Now
                                                            </button>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-11">
                                <div className="pricing__item" data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                                    <div className="pricing__item-inner">
                                        <div className="pricing__item-content">
                                            {/* pricing top part */}
                                            <div className="pricing__item-top d-flex justify-content-between">
                                                <div>
                                                    <h6 className="mb-15">Duration</h6>
                                                    <h3 className="mb-25">30 <span>days</span> </h3>
                                                </div>
                                                <div className="w-50">
                                                    <p className="float-start">Min: <strong>1 </strong></p>
                                                    <p className="float-end">Max: <strong>100 </strong></p>
                                                    <div className="App" style={appStyles}>
                                                        <ProgressBar bgColor={"#fff200"} progress={progress} />
                                                    </div>
                                                    <p className="text-center mt-5">Current Stake</p>
                                                </div>
                                            </div>
                                            {/* pricing middle part */}
                                            <div className="pricing__item-middle pb-0">
                                                <ul className="pricing__list d-flex flex-wrap justify-content-around">
                                                    <li className="pricing__list-item my-3">
                                                        <h5>15%</h5>
                                                        APY
                                                    </li>
                                                    <li className="pricing__list-item my-3">
                                                        <h5>15000000</h5>
                                                        Max Allocation
                                                    </li>
                                                    <li className="pricing__list-item my-3">
                                                        <h5>$1</h5>
                                                        Min Farm
                                                    </li>
                                                    <li className="pricing__list-item my-3">
                                                        <div className="pricing__item-bottom">
                                                            <button type="button" className="trk-btn trk-btn--border trk-btn--primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                Stake Now
                                                            </button>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-11">
                                <div className="pricing__item" data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                                    <div className="pricing__item-inner">
                                        <div className="pricing__item-content">
                                            {/* pricing top part */}
                                            <div className="pricing__item-top d-flex justify-content-between">
                                                <div>
                                                    <h6 className="mb-15">Duration</h6>
                                                    <h3 className="mb-25">30 <span>days</span> </h3>
                                                </div>
                                                <div className="w-50">
                                                    <p className="float-start">Min: <strong>1 </strong></p>
                                                    <p className="float-end">Max: <strong>100 </strong></p>
                                                    <div className="App" style={appStyles}>
                                                        <ProgressBar bgColor={"#fff200"} progress={progress} />
                                                    </div>
                                                    <p className="text-center mt-5">Current Stake</p>
                                                </div>
                                            </div>
                                            {/* pricing middle part */}
                                            <div className="pricing__item-middle pb-0">
                                                <ul className="pricing__list d-flex flex-wrap justify-content-around">
                                                    <li className="pricing__list-item my-3">
                                                        <h5>15%</h5>
                                                        APY
                                                    </li>
                                                    <li className="pricing__list-item my-3">
                                                        <h5>15000000</h5>
                                                        Max Allocation
                                                    </li>
                                                    <li className="pricing__list-item my-3">
                                                        <h5>$1</h5>
                                                        Min Farm
                                                    </li>
                                                    <li className="pricing__list-item my-3">
                                                        <div className="pricing__item-bottom">
                                                            <button type="button" className="trk-btn trk-btn--border trk-btn--primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                Stake Now
                                                            </button>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pricing__shape">
                    <span className="pricing__shape-item pricing__shape-item--5"> <img src="assets/images/icon/shape/3.png" alt="shape-icon" /></span>
                    <span className="pricing__shape-item pricing__shape-item--6"> <img src="assets/images/icon/shape/1.png" alt="shape-icon" />
                    </span>
                </div>
            </section>
            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Farm</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className="my-2">
                                {show
                                    ?
                                    <>
                                        < b >* If you have refral then click <span className='text-green' onClick={handelClickClose}>here</span>.</b> 
                                    </>
                                    :
                                    <>
                                        < b >* If you have refral then click <span className='text-green' onClick={handelClick}>here</span>.</b> 
                                    </>
                                }

                                <input type="text" className={show ? "w-100 mt-2" : "w-100 d-none"} placeholder="Enter Refrral Address" style={{ borderColor: '#00D094' }} />
                            </div>
                            <div className="mt-4">
                                <span className="top-text text-green">Balance: 67750000000000 OXY2</span>
                                <input type="text" className="w-100" placeholder="Enter Amount" style={{ borderColor: '#00D094' }} />
                            </div>
                            <div className="text-center pt-4">
                                <div className="d-flex justify-content-between">
                                    <h6>OxyO2 Rate</h6>
                                    <span className="text-green">$6775</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <h6>Stake</h6>
                                    <span className="text-green">3454 OXY2</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <h6>Harvest(1.5%)</h6>
                                    <span className="text-green">3454 OXY2</span>
                                </div>
                                <h6 className="mt-4 text-green">Tnx Hash</h6>
                                <p className="text-break">0x3f349bBaFEc1551819B8be1EfEA2fC46cA749aA1 <i className="fa fa-copy" /></p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="trk-btn trk-btn--border trk-btn--secondary" data-bs-dismiss="modal">Approve</button>
                            <button type="button" className="trk-btn trk-btn--border trk-btn--primary">Stake Now</button>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}
