import React from 'react'
import CountUp from 'react-countup';

export default function Hero() {
    return (
        <>
            <section className="banner banner--style1">
                <div className="banner__bg">
                    <div className="banner__bg-element">
                        <img src="https://thetork.com/demos/html/bitrader/assets/images/banner/home1/bg-dark.png" alt="section-bg-element" className="dark d-none d-lg-block" />
                        <span className="bg-color d-lg-none" />
                    </div>
                </div>
                <div className="container">
                    <div className="banner__wrapper">
                        <div className="row gy-5 gx-4">
                            <div className="col-lg-6 col-md-7">
                                <div className="banner__content aos-init aos-animate" data-aos="fade-right" data-aos-duration={1000}>
                                    <div className="banner__content-coin">
                                        <img src="assets/images/banner/home1/3.png" alt="coin icon" />
                                    </div>
                                    <h1 className="banner__content-heading mt-4">Don't just buy
                                        <span> OxyO2</span>, Earn it.</h1>
                                    <p className="banner__content-moto">Anyone can invest money to different currency to increase their earnings
                                        by the help of Bitrader through online.
                                    </p>
                                    <a href className="floating-content__item rounded-pill py-2 px-4 fs-6 text-gold text-break">0x3f349bBaFEc1551819B8be1EfEA2fC46cA749aA1 <i className="fa fa-copy" /></a>
                                    <div className="banner__btn-group btn-group mt-4">
                                        <a href="#farm" className="trk-btn trk-btn--primary trk-btn--arrow">Farm Now
                                            <span><svg className="svg-inline--fa fa-arrow-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg>
                                                <path fill="currentColor" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z">
                                                </path>
                                            </svg>{/* <i class="fa-solid fa-arrow-right"></i> Font Awesome fontawesome.com */}</span> </a>
                                        <a href="#" className="trk-btn trk-btn--primary trk-btn--arrow">Buy OxyO2</a>
                                    </div>
                                    <div className="banner__content-social">
                                        <p>Follow Us</p>
                                        <ul className="social">
                                            <li className="social__item">
                                                <a href="#" className="social__link social__link--style1"><svg className="svg-inline--fa fa-facebook-f" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg>
                                                    <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
                                                    </path>
                                                </svg>{/* <i class="fab fa-facebook-f"></i> Font Awesome fontawesome.com */}</a>
                                            </li>
                                            <li className="social__item">
                                                <a href="#" className="social__link social__link--style1"><svg className="svg-inline--fa fa-linkedin-in" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg>
                                                    <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                                                    </path>
                                                </svg>{/* <i class="fab fa-linkedin-in"></i> Font Awesome fontawesome.com */}</a>
                                            </li>
                                            <li className="social__item">
                                                <a href="#" className="social__link social__link--style1"><svg className="svg-inline--fa fa-instagram" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg>
                                                    <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
                                                    </path>
                                                </svg>{/* <i class="fab fa-instagram"></i> Font Awesome fontawesome.com */}</a>
                                            </li>
                                            <li className="social__item">
                                                <a href="#" className="social__link social__link--style1"><svg className="svg-inline--fa fa-youtube" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="youtube" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg>
                                                    <path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z">
                                                    </path>
                                                </svg>{/* <i class="fab fa-youtube"></i> Font Awesome fontawesome.com */}</a>
                                            </li>
                                            <li className="social__item">
                                                <a href="#" className="social__link social__link--style1"><svg className="svg-inline--fa fa-twitter" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                                                    <path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z">
                                                    </path>
                                                </svg>{/* <i class="fab fa-twitter"></i> Font Awesome fontawesome.com */}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-5">
                                <div className="banner__thumb aos-init aos-animate" data-aos="fade-left" data-aos-duration={1000}>
                                    <img src="https://thetork.com/demos/html/bitrader/assets/images/banner/home1/1.png" alt="banner-thumb" className="dark" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="about__wrapper">
                                    <div className="about__thumb pe-lg-5">
                                        <div className="about__thumb-inner">
                                            <div className="about__thumb-image floating-content">
                                                <div className="d-flex flex-wrap justify-content-around mt-4">
                                                    <div className="floating-content my-2">
                                                        <div className="floating-content__item">
                                                            <h3 className='text-gold'> <CountUp delay={1} end={100} />
                                                                Years
                                                            </h3>
                                                            <p>Consulting Experience</p>
                                                        </div>
                                                    </div>
                                                    <div className="floating-content my-2">
                                                        <div className="floating-content__item">
                                                            <h3 className='text-gold'><CountUp delay={1} end={25} />K+
                                                            </h3>
                                                            <p>Satisfied Customers</p>
                                                        </div>
                                                    </div>
                                                    <div className="floating-content my-2">
                                                        <div className="floating-content__item">
                                                            <h3 className='text-gold'><CountUp delay={1} end={25} />K+
                                                            </h3>
                                                            <p>Satisfied Customers</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner__shape">
                    <span className="banner__shape-item banner__shape-item--1"><img src="assets/images/banner/home1/4.png" alt="shape icon" /></span>
                </div>
            </section>
        </>
    )
}
