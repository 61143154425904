import React from 'react'

export default function Overview() {
    return (
        <>
            <div className="service-details pb-20">
                <div className="container-fluid">
                    <div className="service-details__wrapper bg-none mx-md-5 my-md-5 mx-0 my-0">
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                                <div className="service-details__item">
                                    <div className="service-details__item-inn">
                                        <div className="row mb-4">
                                            <h3>Overview</h3>
                                            <div className="col-12 col-md-6 my-3">
                                                <div className="service__item aos-init aos-animate" data-aos="fade-up" data-aos-duration={1000}>
                                                    <div className="service__item-inner text-center p-4" style={{ border: '3px solid #00d094' }}>
                                                        <div className="service__thumb mb-30">
                                                        </div>
                                                        <div className="service__content">
                                                            <p className="mb-15 text-break">0xbe807dddb074639cd9fa61b47676c064fc50d62c
                                                                &nbsp;<i className='fa fa-copy'></i></p>
                                                            <h4 className="mb-0">Address</h4>
                                                        </div>
                                                        <div className="service__content">
                                                            <p className="mb-15 text-break">0xbe807dddb074639cd9fa61b47676c064fc50d62c
                                                                &nbsp;<i className='fa fa-copy'></i></p>
                                                            <h4 className="mb-0">Ref. Address</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 my-3">
                                                <div className="service__item aos-init aos-animate" data-aos="fade-up" data-aos-duration={1000}>
                                                    <div className="service__item-inner text-center p-4" style={{ border: '3px solid #00d094' }}>
                                                        <div className="service__thumb mb-30">
                                                        </div>
                                                        <div className="service__content">
                                                            <p className="mb-0">Locking
                                                                <h4 className="mt-15"> $1000 </h4>
                                                            </p>
                                                        </div>
                                                        <button type="button" className="trk-btn trk-btn--border trk-btn--primary mb-3 mx-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                            Buy Oxyo2
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 my-3">
                                                <div className="service__item aos-init aos-animate" data-aos="fade-up" data-aos-duration={1000}>
                                                    <div className="service__item-inner text-center p-4" style={{ border: '3px solid #00d094' }}>
                                                        <div className="service__thumb mb-30">
                                                        </div>
                                                        <div className="service__content">
                                                            <h2 className="mb-15"> $1000 </h2>
                                                            <p className="mb-0">Locking
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 my-3">
                                                <div className="service__item aos-init aos-animate" data-aos="fade-up" data-aos-duration={1000}>
                                                    <div className="service__item-inner text-center p-4" style={{ border: '3px solid #00d094' }}>
                                                        <div className="service__thumb mb-30">
                                                        </div>
                                                        <div className="service__content">
                                                            <h2 className="mb-15"> $1000 </h2>
                                                            <p className="mb-0">Harvest
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 my-3">
                                                <div className="service__item aos-init aos-animate" data-aos="fade-up" data-aos-duration={1000}>
                                                    <div className="service__item-inner text-center p-4" style={{ border: '3px solid #00d094' }}>
                                                        <div className="service__thumb mb-30">
                                                        </div>
                                                        <div className="service__content">
                                                            <h2 className="mb-15"> $1000 </h2>
                                                            <p className="mb-0">Referral Reward
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 my-3">
                                                <div className="service__item aos-init aos-animate" data-aos="fade-up" data-aos-duration={1000}>
                                                    <div className="service__item-inner text-center p-4" style={{ border: '3px solid #00d094' }}>
                                                        <div className="service__thumb mb-30">
                                                        </div>
                                                        <div className="service__content">
                                                            <h2 className="mb-15"> $1000 </h2>
                                                            <p className="mb-0">Withdrawal
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 my-3">
                                                <div className="service__item aos-init aos-animate" data-aos="fade-up" data-aos-duration={1000}>
                                                    <div className="service__item-inner text-center p-4" style={{ border: '3px solid #00d094' }}>
                                                        <div className="service__thumb mb-30">
                                                        </div>
                                                        <div className="service__content">
                                                            <h2 className="mb-15"> $1000 </h2>
                                                            <p className="mb-0">Team
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <h3>Farm List</h3>
                                            <div className="pricing__wrapper">
                                                <div className="row g-4 align-items-center justify-content-center">
                                                    <div className="col-md-6 col-12">
                                                        <div className="pricing__item aos-init" data-aos="fade-right" data-aos-duration={1000}>
                                                            <div className="pricing__item-inner" style={{ border: '3px solid #00d094' }}>
                                                                <div className="pricing__item-content">
                                                                    {/* pricing top part */}
                                                                    <div className="pricing__item-top d-flex flex-wrap justify-content-md-between justify-content-center" style={{ border: '0px' }}>
                                                                        <div className="p-2">
                                                                            <h6 className="mb-15 text-center fs-6">Duration</h6>
                                                                            <h3 className="mb-25 text-center fs-3">30 <span>days</span> </h3>
                                                                        </div>
                                                                        <div className="p-2">
                                                                            <div>
                                                                                <h6 className="mb-15 text-center fs-6">Amount</h6>
                                                                                <h3 className="mb-25 fs-3">$10000 </h3>
                                                                            </div>
                                                                        </div>
                                                                        <div className="p-2">
                                                                            <h6 className="mb-15 text-center fs-6">Endtime</h6>
                                                                            <h3 className="mb-25 fs-3">47:59:59 </h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pricing__item-top d-flex justify-content-center" style={{ border: '0px' }}>
                                                                        <div>
                                                                            <h6 className="mb-15 text-center fs-6" style={{ color: '#fff200' }}>Harvest</h6>
                                                                            <h4 className="mb-25">$6.6666666666 </h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pricing__item-middle pb-0 pt-2">
                                                                        <div className="pricing__item-bottom text-center d-flex justify-content-center">
                                                                            <button type="button" className="trk-btn trk-btn--border trk-btn--primary mb-2 mx-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                                Unfarm
                                                                            </button>
                                                                            <button type="button" className="trk-btn trk-btn--border trk-btn--primary mb-2 mx-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                                Claim
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="pricing__item aos-init" data-aos="fade-right" data-aos-duration={1000}>
                                                            <div className="pricing__item-inner" style={{ border: '3px solid #00d094' }}>
                                                                <div className="pricing__item-content">
                                                                    {/* pricing top part */}
                                                                    <div className="pricing__item-top d-flex flex-wrap justify-content-md-between justify-content-center" style={{ border: '0px' }}>
                                                                        <div className="p-2">
                                                                            <h6 className="mb-15 text-center fs-6">Duration</h6>
                                                                            <h3 className="mb-25 text-center fs-3">30 <span>days</span> </h3>
                                                                        </div>
                                                                        <div className="p-2">
                                                                            <div>
                                                                                <h6 className="mb-15 text-center fs-6">Amount</h6>
                                                                                <h3 className="mb-25 fs-3">$10000 </h3>
                                                                            </div>
                                                                        </div>
                                                                        <div className="p-2">
                                                                            <h6 className="mb-15 text-center fs-6">Endtime</h6>
                                                                            <h3 className="mb-25 fs-3">47:59:59 </h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pricing__item-top d-flex justify-content-center" style={{ border: '0px' }}>
                                                                        <div>
                                                                            <h6 className="mb-15 text-center fs-6" style={{ color: '#fff200' }}>Harvest</h6>
                                                                            <h4 className="mb-25">$6.6666666666 </h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pricing__item-middle pb-0 pt-2">
                                                                        <div className="pricing__item-bottom text-center d-flex justify-content-center">
                                                                            <button type="button" className="trk-btn trk-btn--border trk-btn--primary mb-2 mx-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                                Unfarm
                                                                            </button>
                                                                            <button type="button" className="trk-btn trk-btn--border trk-btn--primary mb-2 mx-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                                Claim
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="pricing__item aos-init" data-aos="fade-right" data-aos-duration={1000}>
                                                            <div className="pricing__item-inner" style={{ border: '3px solid #00d094' }}>
                                                                <div className="pricing__item-content">
                                                                    {/* pricing top part */}
                                                                    <div className="pricing__item-top d-flex flex-wrap justify-content-md-between justify-content-center" style={{ border: '0px' }}>
                                                                        <div className="p-2">
                                                                            <h6 className="mb-15 text-center fs-6">Duration</h6>
                                                                            <h3 className="mb-25 text-center fs-3">30 <span>days</span> </h3>
                                                                        </div>
                                                                        <div className="p-2">
                                                                            <div>
                                                                                <h6 className="mb-15 text-center fs-6">Amount</h6>
                                                                                <h3 className="mb-25 fs-3">$10000 </h3>
                                                                            </div>
                                                                        </div>
                                                                        <div className="p-2">
                                                                            <h6 className="mb-15 text-center fs-6">Endtime</h6>
                                                                            <h3 className="mb-25 fs-3">47:59:59 </h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pricing__item-top d-flex justify-content-center" style={{ border: '0px' }}>
                                                                        <div>
                                                                            <h6 className="mb-15 text-center fs-6" style={{ color: '#fff200' }}>Harvest</h6>
                                                                            <h4 className="mb-25">$6.6666666666 </h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="pricing__item-middle pb-0 pt-2">
                                                                        <div className="pricing__item-bottom text-center d-flex justify-content-center">
                                                                            <button type="button" className="trk-btn trk-btn--border trk-btn--primary mb-2 mx-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                                Unfarm
                                                                            </button>
                                                                            <button type="button" className="trk-btn trk-btn--border trk-btn--primary mb-2 mx-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                                Claim
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
