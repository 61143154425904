import React from 'react'

export default function StakedAssets() {
    return (
        <>
            <section className="banner banner--style1">
                <div className="banner__bg">
                    <div className="banner__bg-element">
                        <img src="assets/images/banner/home1/bg-dark.png" style={{ zIndex: "-99" }} alt="section-bg-element" className="dark d-none d-lg-block" />
                        <span className="bg-color d-lg-none" />
                    </div>
                </div>
                <div className="container">
                    <div className="banner__wrapper">
                        <div className="row gy-5 gx-4">
                            <div className="col-lg-6 col-md-7">
                                <div className="banner__content" data-aos="fade-right" data-aos-duration={1000}>
                                    <div className="banner__content-coin">
                                        <img src="assets/images/banner/home1/3.png" alt="coin icon" />
                                    </div>
                                    <h5>Get access to the value of your staked assets. Unleash the potential of your Oxyo2Farm.</h5>
                                    <div className="banner__btn-group btn-group">
                                        <a href="signin.html" className="trk-btn trk-btn--primary trk-btn--arrow">Liquid Staking
                                            <span><i className="fa-solid fa-arrow-right" /></span> </a>
                                    </div>
                                    <h1 className="banner__content-heading">$43,080,034</h1>
                                    <p className="banner__content-moto fs-4">Total Value Locked</p>

                                    <h1 className="banner__content-heading">3585</h1>
                                    <p className="banner__content-moto fs-4">Active Stakers</p>
                                    
                                    {/* <div className="banner__content-social">
                                        <p>Follow Us</p>
                                        <ul className="social">
                                            <li className="social__item">
                                                <a href="#" className="social__link social__link--style1 active"><i className="fab fa-facebook-f" /></a>
                                            </li>
                                            <li className="social__item">
                                                <a href="#" className="social__link social__link--style1"><i className="fab fa-linkedin-in" /></a>
                                            </li>
                                            <li className="social__item">
                                                <a href="#" className="social__link social__link--style1"><i className="fab fa-instagram" /></a>
                                            </li>
                                            <li className="social__item">
                                                <a href="#" className="social__link social__link--style1"><i className="fab fa-youtube" /></a>
                                            </li>
                                            <li className="social__item">
                                                <a href="signin.html" className="social__link social__link--style1"><i className="fab fa-twitter" /></a>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-5">
                                <div className="banner__thumb d-lg-none">
                                    <img src="assets/images/banner/home1/bg2.png" alt="banner-thumb" class="dark" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner__shape">
                    <span className="banner__shape-item banner__shape-item--1"><img src="assets/images/banner/home1/4.png" alt="shape icon" /></span>
                </div>
            </section>

        </>
    )
}
