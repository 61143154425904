import React from 'react'

export default function Steps() {
    return (
        <>
            <section className="feature feature--style2 padding-bottom padding-top feature-bg-color bg--cover" style={{ backgroundImage: 'url(assets/images/feature/home3/bg.png)' }}>
                <div className="section-header section-header--style3 section-header--max57">
                    <h2 className="mb-15 mt-minus-5">Farm <span className="text-green">OxyO2</span><br /> with 3 easy steps</h2>
                    <p>Oxyo2farm a decentralized, secure and anonymous platform platform.
                    </p>
                </div>
                <div className="container">
                    <div className="feature__wrapper">
                        <div className="row g-4 align-items-center">
                            <div className="col-sm-6 col-lg-4">
                                <div className="feature__item" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                                    <div className="feature__item-inner text-center">
                                        <div className="feature__item-thumb">
                                            <img className="dark inner-img" src="assets/images/feature/home3/1-dark.png" alt="feature-item-icon" />
                                        </div>
                                        <div className="feature__item-content">
                                            <h5> Connect Wallet </h5>
                                            <p>Connect any Web 3.0 wallet via your browser.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                                <div className="feature__item" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                                    <div className="feature__item-inner text-center">
                                        <div className="feature__item-thumb">
                                            <img className="dark inner-img" src="assets/images/feature/home3/2-dark.png" alt="feature-item-icon" />
                                        </div>
                                        <div className="feature__item-content">
                                            <h5> Select Package</h5>
                                            <p>Select your desired package of oxyo2 farming.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                                <div className="feature__item" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                                    <div className="feature__item-inner text-center">
                                        <div className="feature__item-thumb">
                                            <img className="dark inner-img" src="assets/images/feature/home3/3-dark.png" alt="feature-item-icon" />
                                        </div>
                                        <div className="feature__item-content">
                                            <h5> Start Rewards </h5>
                                            <p>Smart contracts will do all the technical work automatically for you.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="feature__shape">
                    <span className="feature__shape-item feature__shape-item--1"><img src="assets/images/feature/home3/5.png" alt="shape-icon" /></span>
                </div>
            </section>

        </>
    )
}
