import React from 'react'

export default function Rate() {

    return (
        <>
            <section className="about about--style3 padding-top padding-bottom ">
                <div className="container">
                    <div className="about__wrapper">
                        <div className="row g-5 align-items-center">
                            <div className="col-md-5">
                                <div className="about__content aos-init aos-animate" data-aos="fade-left" data-aos-duration={800}>
                                    <div className="about__content-icon">
                                        <img src="assets/images/banner/home3/2.png" alt="trade icon" />
                                    </div>
                                    <h2>Meet our platform to know more about</h2>
                                    <p className="mb-0">Hey folks! Meet our platform! With our platform, you'll get access to all the info you
                                        need to make informed decisions. Our platform is your one-stop-shop for everything you need to know.
                                        So what are you waiting for? Let's get started! </p>
                                    <a href="about.html" className="trk-btn trk-btn--border trk-btn--primary">Explore More </a>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 col-sm-12 my-2'>
                                        <div className="testimonial__item testimonial__item--style2">
                                            <div className="testimonial__item-inner">
                                                <div className="testimonial__item-content">
                                                    <div className="mb-0 d-flex justify-content-between"> <span className='text-gold'>1H Changes</span> <span className='text-danger '>-0.02%</span> </div>
                                                    <p className="mb-0 d-flex justify-content-between"><span className='text-gold'>24H High/Low</span> <span className='text-gold'>$280000/$280000</span></p>
                                                    <div className="testimonial__footer">
                                                        <div className="testimonial__author">
                                                            <div className="testimonial__author-thumb">
                                                                <img src="/assets/images/testimonial/preloader.png" alt="author" className="dark" />
                                                            </div>
                                                            <div className="testimonial__author-designation">
                                                                <h4 className='text-green'>$280000</h4>
                                                                <span>Btcoin</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="testimonial__item testimonial__item--style2 box-shadow">
                                            <div className="testimonial__item-inner">
                                            </div>
                                        </div>
                                        <div className="testimonial__item testimonial__item--style2 box-shadow2">
                                            <div className="testimonial__item-inner">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 my-2'>
                                        <div className="testimonial__item testimonial__item--style2">
                                            <div className="testimonial__item-inner">
                                                <div className="testimonial__item-content">
                                                    <div className="mb-0 d-flex justify-content-between"> <span className='text-gold'>1H Changes</span> <span className='text-danger '>-0.02%</span> </div>
                                                    <p className="mb-0 d-flex justify-content-between"><span className='text-gold'>24H High/Low</span> <span className='text-gold'>$280000/$280000</span></p>
                                                    <div className="testimonial__footer">
                                                        <div className="testimonial__author">
                                                            <div className="testimonial__author-thumb">
                                                                <img src="/assets/images/testimonial/preloader.png" alt="author" className="dark" />
                                                            </div>
                                                            <div className="testimonial__author-designation">
                                                                <h4 className='text-green'>$280000</h4>
                                                                <span>Btcoin</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="testimonial__item testimonial__item--style2 box-shadow">
                                            <div className="testimonial__item-inner">
                                            </div>
                                        </div>
                                        <div className="testimonial__item testimonial__item--style2 box-shadow2">
                                            <div className="testimonial__item-inner">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row my-lg-4  my-sm-0'>
                                    <div className='col-lg-6 col-md-6 col-sm-12 my-2'>
                                        <div className="testimonial__item testimonial__item--style2">
                                            <div className="testimonial__item-inner">
                                                <div className="testimonial__item-content">
                                                    <div className="mb-0 d-flex justify-content-between"> <span className='text-gold'>1H Changes</span> <span className='text-danger '>-0.02%</span> </div>
                                                    <p className="mb-0 d-flex justify-content-between"><span className='text-gold'>24H High/Low</span> <span className='text-gold'>$280000/$280000</span></p>
                                                    <div className="testimonial__footer">
                                                        <div className="testimonial__author">
                                                            <div className="testimonial__author-thumb">
                                                                <img src="/assets/images/testimonial/preloader.png" alt="author" className="dark" />
                                                            </div>
                                                            <div className="testimonial__author-designation">
                                                                <h4 className='text-green'>$280000</h4>
                                                                <span>Btcoin</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="testimonial__item testimonial__item--style2 box-shadow">
                                            <div className="testimonial__item-inner">
                                            </div>
                                        </div>
                                        <div className="testimonial__item testimonial__item--style2 box-shadow2">
                                            <div className="testimonial__item-inner">
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 my-2'>
                                        <div className="testimonial__item testimonial__item--style2">
                                            <div className="testimonial__item-inner">
                                                <div className="testimonial__item-content">
                                                    <div className="mb-0 d-flex justify-content-between"> <span className='text-gold'>1H Changes</span> <span className='text-danger '>-0.02%</span> </div>
                                                    <p className="mb-0 d-flex justify-content-between"><span className='text-gold'>24H High/Low</span> <span className='text-gold'>$280000/$280000</span></p>
                                                    <div className="testimonial__footer">
                                                        <div className="testimonial__author">
                                                            <div className="testimonial__author-thumb">
                                                                <img src="/assets/images/testimonial/preloader.png" alt="author" className="dark" />
                                                            </div>
                                                            <div className="testimonial__author-designation">
                                                                <h4 className='text-green'>$280000</h4>
                                                                <span>Btcoin</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="testimonial__item testimonial__item--style2 box-shadow">
                                            <div className="testimonial__item-inner">
                                            </div>
                                        </div>
                                        <div className="testimonial__item testimonial__item--style2 box-shadow2">
                                            <div className="testimonial__item-inner">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
