import React from 'react'

export default function Platform() {
    return (
        <>
            <section className="feature feature--style2 padding-bottom padding-top feature-bg-color bg--cover" style={{ backgroundImage: 'url(assets/images/feature/home3/bg.pn)' }}>
                {/* <div className="section-header section-header--style3 section-header--max57">
                    <h2 className="mb-15 mt-minus-5">Farm <span className="text-green">OxyO2</span><br /> with 3 easy steps</h2>
                    <p>Oxyo2farm a decentralized, secure and anonymous platform platform.
                    </p>
                </div> */}
                <div className="container">
                    <div className="feature__wrapper">
                        <div className="row g-4 align-items-center">
                            <div className="col-sm-6 col-lg-4">
                                <div className="feature__item" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                                    <div className="feature__item-inner text-center">
                                        <div className="feature__item-content my-3">
                                            <h5 className='text-green'>Trusted Crypto Platform</h5>
                                            <p>Oxyo2farm is based in the following 4 principles</p>
                                        </div>
                                        <div className="feature__item-thumb">
                                            <img className="dark inner-img" src="assets/images/feature/home3/10-dark.png" alt="feature-item-icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-8">
                                <div className="row g-4 align-items-center">
                                    <div className="col-sm-6 col-lg-4">
                                        <div className="feature__item" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                                            <div className="feature__item-inner text-center">
                                                <div className="feature__item-thumb">
                                                    <img className="dark inner-img" src="assets/images/feature/home3/3-dark.png" alt="feature-item-icon"  width="200"/>
                                                </div>
                                                <div className="feature__item-content">
                                                    <h5 className='text-green'>SECURE</h5>
                                                    <p>3rd party audited smart contracts.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <div className="feature__item" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                                            <div className="feature__item-inner text-center">
                                                <div className="feature__item-thumb"> 
                                                    <img className="dark inner-img" src="assets/images/feature/home3/2-dark.png" alt="feature-item-icon" width="200"/>
                                                </div>
                                                <div className="feature__item-content">
                                                    <h5 className='text-green'>SIMPLE</h5>
                                                    <p>Easy to use from <br /> any device.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <div className="feature__item" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                                            <div className="feature__item-inner text-center">
                                                <div className="feature__item-thumb">
                                                    <img className="dark inner-img" src="assets/images/feature/home3/1-dark.png" alt="feature-item-icon" width="200" />
                                                </div>
                                                <div className="feature__item-content">
                                                    <h5 className='text-green'>ANONYMOUS</h5>
                                                    <p>Simply connect your Web3 wallet.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-4 align-items-center mt-3">
                                    <div className="col-sm-6 col-lg-12">
                                        <div className="feature__item" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                                            <div className="feature__item-inner text-lg-start text-center">
                                                <div className="feature__item-thumb">
                                                    <img className="dark inner-img" src="assets/images/feature/home3/1-dark.png" alt="feature-item-icon" />
                                                </div>
                                                <div className="feature__item-content">
                                                    <h5 className='text-green'>DECENTRALIZED</h5>
                                                    <p>Oxyo2farm is fully decentralised and independent platform available for your digital assets without restrictions or limitations.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="feature__shape">
                    <span className="feature__shape-item feature__shape-item--1"><img src="assets/images/feature/home3/5.png" alt="shape-icon" /></span>
                </div>
            </section>
        </>
    )
}
