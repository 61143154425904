import React from 'react'

export default function Oxyo2farm() {
    return (
        <>
            <section className="about about--style3 padding-top" id='farm'>
                <div className="container">
                    <div className="about__wrapper">
                        <div className="row g-5 text-center justify-content-center">
                            <div className="col-md-8">
                                <div className="about__content" data-aos="fade-left" data-aos-easing="ease-in-out" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
                                    <div className="about__content-icon">
                                        <img src="assets/images/banner/home3/2.png" alt="trade icon" />
                                    </div>
                                    <h5 className="text-green">Oxyo2farm</h5>
                                    <h2>A unique and sustainable way to earn passive income</h2>
                                    <p className="mb-0">Hey folks! Meet our platform! With our platform, you'll get access to all the info you
                                        need to make informed decisions. Our platform is your one-stop-shop for everything you need to know.
                                        So what are you waiting for? Let's get started! Lorem, ipsum dolor sit amet consectetur adipisicing
                                        elit. Officiis, exercitationem omnis dolorum dignissimos totam molestias. Alias possimus quo eos id,
                                        quod deleniti aliquid eum numquam voluptatem recusandae quas ut vitae!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
