import React, { useState, useEffect } from "react";

const Timer = () => {
  const totalsecond = 592435
  // const 
  const [secondsRemaining, setSecondsRemaining] = useState(totalsecond);

  useEffect(() => {
    if (secondsRemaining > 0) {
      const timer = setTimeout(() => {
        setSecondsRemaining(secondsRemaining - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [secondsRemaining]);

  const days = Math.floor(secondsRemaining / 86400);
  const hours = Math.floor((secondsRemaining % 86400) / 3600);
  const minutes = Math.floor((secondsRemaining % 3600) / 60);
  const seconds = secondsRemaining % 60;

  return (
    <div className="timer-container">
      <div className="timer">
        {`${days.toString().padStart(2, "0")}:${hours
          .toString()
          .padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`}
      </div>
    </div>
  );
};

export default Timer;
