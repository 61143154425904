import React from 'react'
import Header2 from '../Comman/Header2'
import Footer2 from '../Comman/Footer2'
import Sidebar from '../Comman/Sidebar'
import Sidebar2 from '../Comman/Sidebar2'
import './sidebar.css'
import Overview from './Overview'
import PendingReward from './PendingReward'
export default function Dashboard() {

    return (
        <>
            {/* <Header2 /> */}
            <Sidebar2 />
            <Overview />
            <PendingReward />
            <Footer2 />
        </>
    )
}
